import React, {Component} from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import $ from 'jquery';
import Cookies from 'js-cookie';

import LayoutLPTwo from '../components/layoutLPTwo';
import SEO from "../components/seo";

import './strategicalliancestrends.scss';
import heroOverlay from '../images/hero-overlay-img.webp';

import FormField from '../components/utilities/FormField';

class StrategicForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: ''
        };

        this.submit = this.submit.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.phoneChange = this.phoneChange.bind(this);
        this.companyChange = this.companyChange.bind(this);
    }

    submit(e) {
        e.preventDefault();
        let formObject = $('#heroForm');
        let postDt = new Date();
        let loadDt = new Date();
        //let _sid = 'qq3eu25egawyabolsjpyzcr5';
        let _sid = Cookies.get('ASP.NET_SessionId');
        if (formObject.find('input[name="js"]').length === 0) {
            let str = '<input type="hidden" name="js" value="1" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="ttp"]').length === 0) {
            let str = '<input type="hidden" name="ttp" value="' + ((postDt - loadDt) / 1000).toString() + '" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="sid"]').length === 0) {
            let str = '<input type="hidden" name="sid" value="' + _sid + '" />';
            formObject.append(str);
        } else {
            formObject.find('input[name="sid"]').val(_sid);
        }

        let post_data = formObject.serialize();

        // console.log(post_data);

        this.setState({
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: ''
        });

        $.ajax({
            url: "https://metrix.meritmile.com/email.aspx?ajax=1",
            type: 'POST',
            data: post_data,
            cache: false,
            dataType: 'json',
            success: function(data) {
                // Success..
                // let r = data[0];
                // let redirect_url = "https://www.meritmile.com/uploads/2019/01/Merit-Mile-Industry-Pulse-2019-Outdoor-Retail-Industry-Trends.pdf";

                // formObject.find('.feedback').show().removeClass('alert-error').addClass('alert-success').html(r.Message).css('display', 'block').focus();
                // window.location.href = redirect_url;

                $('#heroForm').hide();
                $('.form-wrapper .thankyou').show();
                
                window.open(
                    'https://mm-stats.com/6e1kc ',
                    '_blank' // <- This is what makes it open in a new tab.
                );
            },
            // Fail..
            error: function(result) {
                let r = result[0];

                formObject.find('.feedback').show().addClass('alert-error').html(r.Message).css('display', 'block').focus();
                formObject.find('.submit').attr('disabled', false).removeClass('disabled');
            }
        });
    }

    nameChange(e){
        this.setState({contactName: e.target.value})
    }

    emailChange(e){
        this.setState({contactEmail: e.target.value})
    }

    phoneChange(e){
        this.setState({contactPhone: e.target.value})
    }

    companyChange(e){
        this.setState({contactCompany: e.target.value})
    }

    render() {
        return (
            <form id="heroForm" name="metrixform" method="POST" action="https://metrix.meritmile.com/email.aspx" onSubmit={this.submit}>
                <FormField fieldID={"name"} valChange={this.nameChange} val={this.state.contactName} type={"text"}  name={"name"} label={"Full Name"}/>
                <FormField fieldID={"email"}  valChange={this.emailChange} val={this.state.contactEmail} type={"email"}  name={"email"} label={"Email"}/>
                <FormField fieldID={"company"}  valChange={this.companyChange} val={this.state.contactCompany} type={"text"}  name={"company"} label={"Company"}/>
                <FormField fieldID={"telephone"}  valChange={this.phoneChange} val={this.state.contactPhone} type={"tel"} name={"telephone"} label={"Telephone"}/>
                <button type="submit" className="btn btn-white">Get the Infographic</button>
                <div className="form-group">
                    <input type="hidden" name="referrer" value="https://meritmile.com/technology-marketing/strategic-alliances-2019-trends-infographic/" />
                    <input type="hidden" name="auth" value="0d5382dd-50a8-40e1-a3af-385735f536fd" />
                    <input type="text" className="d-none hu" id="human-check" name="human-check" />
                    <div className="feedback"></div>
                    <div className="fix"></div>
                </div>
            </form>
        )
    }
}

export default ({pageContext}) => (
    <LayoutLPTwo>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />        
        <div className="strategicalliancestrends-wrapper">
            <div className="lp-hero">
                <div className="container margin-b">
                    <div className="row margin-b">
                        <div className="col-md-8 hero-title">
                            <img src={heroOverlay} alt=""/>
                        </div>
                        <div className="col-md-4 hero-bg">
                            <div className="inside-hero text-center">
                                <h2>What's the weak link in strategic alliances?</h2>
                                <p>Read the infographic to find out how to ensure your alliances skyrocket, not sink.</p>
                                <div className="form-wrapper">
                                    <StrategicForm />
                                    <div className="thankyou">
                                        <h4>Thank You</h4>
                                        <p>If the infographic does not immediately open please check your pop-up blocker. A copy will also be sent to your email.</p>
                                        <p>Add info@meritmile.com to your contacts to ensure future communications will not be sent to spam.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="container mainContent">
                <div className="row margin-b">
                    <div className="col-12 col-md-8">
                        <h1>Strengthen Your Strategic Alliances</h1>
                        <p>Strategic alliance programs are on the rise and the benefits are clear – more leads, bigger deals, and greater marketplace awareness.</p>
                        <p>But successfully developing and executing an alliance marketing plan is easier said than done. Download the <AnchorLink href="#root" offset='100' className="content-link">Strategic Alliances 2019 Marketing Trends infographic</AnchorLink> to find out:</p>
                        <ul>
                            <li>What challenges alliance managers and marketers face</li>
                            <li>Why alliance marketers struggle to execute plans</li>
                            <li>How alliance professionals overcome these challenges</li>
                        </ul>
                         <AnchorLink href="#root" offset='100' className="btn btn-primary btn-lg btn-block">DOWNLOAD THE INFOGRAPHIC</AnchorLink>
                    </div> 
                    <div className="col-6 offset-3 col-md-2 offset-md-1">
                        <div className="border-left">
                            <img className="img-fluid msft" alt="Video 1" src="https://www.meritmile.com/uploads/2019/07/msft_logo_print@2x.png" />
                            <img className="img-fluid google" alt="Video 2" src="https://www.meritmile.com/uploads/2019/07/cloud-lockup-color@2x.png" />
                            <img className="img-fluid ibm" alt="Video 3" src="https://www.meritmile.com/uploads/2019/07/ibm_logo@2x.png" />
                            <img className="img-fluid tsy" alt="Video 4" src="https://www.meritmile.com/uploads/2019/07/tsy_logo_4c_p@2x.png" />
                            <img className="img-fluid citrix" alt="Video 5" src="https://www.meritmile.com/uploads/2019/07/citrix_logo_black@2x.png" />
                            <img className="img-fluid tcs" alt="Video 6" src="https://www.meritmile.com/uploads/2019/07/tcsmarkstacked3line_cmyk@2x.png"  />
                            <img className="img-fluid dxc" alt="Video 7" src="https://www.meritmile.com/uploads/2019/07/dxc_logo_hz_1c_blk_cmyk@2x.png" />
                            <img className="img-fluid fujitsu" alt="Video 8" src="https://www.meritmile.com/uploads/2019/07/fujitsu-logo-color@2x.png" />
                        </div>
                     </div>
                </div>
            </div>
        </div>
    </LayoutLPTwo>
)